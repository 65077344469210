<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import LoaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Erreur } from "../../../helpers/error";
import { Api } from "../../../helpers";
import VideosCard from "../../../components/widgets/VideosCard.vue";
import addVideo from "../../../components/widgets/addVideo.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    LoaderProcess,
    VideosCard,
    addVideo,
  },

  data() {
    return {
      pageSize: 30, // Nombre d'éléments à afficher sur chaque page
      currentPage: 0,
      startDate: "",
      endDate: "",
      titre: "",
      progress: 0,
      searchQuery: "",
      categories: [],
      categorieList: [],
      categorie: [],
      process: false,
      tags: [],
      tagList: [],
      channels: [],
      programList: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      spinner: false,
      title: "Liste de Vidéos",
      items: [
        {
          text: "Contenu",
          // href: "/projects/categories",
        },
        {
          text: "Vidéos",
          active: true,
        },
      ],
      activeFilter: "all",
      filteredVideos: [],
    };
  },
  methods: {
    filterVideos(filter) {
      if (filter === "all") {
        this.filteredVideos = this.videos;
      } else if (filter === "published") {
        this.filteredVideos = this.videos.filter(
          (video) => video.isPublished === true
        );
      } else if (filter === "unpublished") {
        this.filteredVideos = this.videos.filter(
          (video) => video.isPublished === false
        );
      }
      this.filter = filter; // Mettre à jour l'état du filtre actif

      // Mettre à jour le filtre actif
      this.activeFilter = filter;
    },
  },
  mounted() {
    this.spinner = true;
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    };

    Api.get("/streamvod/api/v2/video/all", onUploadProgress)
      .then((res) => {
        this.spinner = false;
        this.videos = res.data.content;
        console.log(this.videos);
        this.filterVideos("all");
      })
      .catch((error) => {
        this.spinner = false;
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvod/api/v2/category/all", onUploadProgress)
      .then((res) => {
        this.categories = res.data.content;
        this.categorieList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvod/api/v2/tag/all", onUploadProgress)
      .then((res) => {
        this.tagList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvod/api/v2/program/all", onUploadProgress)
      .then((res) => {
        this.programList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });
  },
  watch: {
    startDate(newVal) {
      this.endDate = newVal; // Mettre la valeur minimale à la nouvelle valeur de startDate
    },
  },
  computed: {
    filteredVideosList() {
      if (!this.startDate || !this.endDate) {
        if (this.searchQuery) {
          return this.videos.slice().filter((video) => {
            const title = video.title.toLowerCase();
            const searchQuery = this.searchQuery.toLowerCase();

            return title.includes(searchQuery);
          });
        }
        return this.videos.slice().reverse();
      }

      const start = new Date(this.startDate).getTime();
      const end = new Date(this.endDate).getTime();

      return this.videos.slice().filter((video) => {
        const createdAt = new Date(video.created).getTime();
        const title = video.title.toLowerCase();
        const searchQuery = this.searchQuery.toLowerCase();

        return (
          createdAt >= start && createdAt <= end && title.includes(searchQuery)
        );
      });
    },
    pages() {
      // Générer une liste de nombres de pages à afficher dans la pagination
      let pages = [];
      for (let i = 0; i <= this.pageCount - 1; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageCount() {
      return Math.ceil(this.filteredVideosList.length / this.pageSize);
    },
    paginatedVideos() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredVideosList.slice(start, end);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <loader-process :visible="process" :progress="progress" :title="titre" />

    <div class="row mb-3">
      <div class="col-lg-5">
        <br /><br />
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher une vidéo"
            style="border-radius: 8px"
            v-model="searchQuery"
          />
        </div>
      </div>

      <div class="col-lg-7" style="text-align: end">
        <br /><br />
        <addVideo></addVideo>
      </div>
    </div>
    <!-- <div class="row">
      <div style="display: flex; align-items: center; justify-content: center">
        <button
          @click="filterArticles('all')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'all' }"
        >
          Tous
        </button>
        <button
          @click="filterArticles('published')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'published' }"
        >
          Publiés
        </button>
        <button
          @click="filterArticles('unpublished')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'unpublished' }"
        >
          Non publiés
        </button>
      </div>
    </div> -->

    <br />
    <div
      class="row"
      v-if="
        categorieList.length > 0 && tagList.length > 0 && programList.length > 0
      "
    >
      <VideosCard
        v-for="video in paginatedVideos"
        :key="video.id"
        :video-props="video"
        :chaines="channels"
        :categ="categorieList"
        :tag="tagList"
        :programme="programList"
      ></VideosCard>
    </div>

    <div style="text-align: center; font-size: 3em" v-if="spinner == true">
      <b-spinner variant="success" role="status"></b-spinner>
    </div>
    <div
      style="text-align: center; font-size: 3em"
      v-if="spinner == false && paginatedVideos.length == 0"
    >
      <span style="font-size: 1.5rem">Aucune vidéo créé !</span>
    </div>
    <div class="row" v-if="pages.length > 1">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <!-- <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 70px">Image</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Durée</th>
                    <th scope="col">Likes</th>
                    <th scope="col">Commentaires</th>
                    <th scope="col">Vues</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
              </table> -->
              <div
                style="text-align: center; font-size: 3em"
                v-if="spinner == true"
              >
                <b-spinner variant="success" role="status"></b-spinner>
              </div>

              <nav
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <ul class="pagination">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 0 }"
                  >
                    <button
                      class="page-link"
                      style="
                        margin: 5px;
                        font-size: 15px;
                        border-radius: 20px 0 0 20px;
                      "
                      @click="goToPage(currentPage - 1)"
                    >
                      Précédent
                    </button>
                  </li>
                  <li v-for="page in pages" :key="page" class="page-item">
                    <button
                      class="page-link"
                      :class="{ active2play: currentPage === page }"
                      style="
                        height: 45px;
                        width: 45px;
                        border-radius: 50%;
                        margin: 5px;
                      "
                      @click="goToPage(page)"
                    >
                      {{ page + 1 }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === pageCount - 1 }"
                  >
                    <button
                      class="page-link"
                      style="
                        margin: 5px;
                        font-size: 15px;
                        border-radius: 0 20px 20px 0;
                      "
                      @click="goToPage(currentPage + 1)"
                    >
                      Suivant
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style></style>
